import React, { forwardRef, ReactNode, RefObject } from "react"
import ReactLoading from "react-loading";
import { Link } from "gatsby";

import { appendVariantClasses, formatClassList } from "../../utils"

import { Color } from "../../@types/colors"


type ButtonIcon =
  | "download"
  | "external"

export type ButtonProps = {
  action: "primary" | "secondary" | "tertiary",
  children: ReactNode,
  className?: string,
  color: Color,
  disabled: boolean,
  href?: string,
  icon?: ButtonIcon
  loading?: boolean,
  title: string,
  [rest: string]: unknown
}

const BTN_COMMON: string = `
  align-baseline
  focus:outline-none
  font-sans
  inline-block
  outline-none
  overflow-hidden
  self-start
  tracking-wider
  transition-all
`

const PRIMARY_SECONDARY_BASE: string = `
  duration-400
  ease-in-out
  font-medium
  hover:scale-105
  disabled:scale-100
  px-10
  sm:px-14
  md:px-6
  py-2
  rounded-lg
  transform
`

const PRIMARY_COMMON: string = `
  ${BTN_COMMON}
  ${PRIMARY_SECONDARY_BASE}
  focus:ring-4
`

const SECONDARY_COMMON: string = `
  ${BTN_COMMON}
  ${PRIMARY_SECONDARY_BASE}
  focus:ring-2
`

const TERTIARY_COMMON: string = `
  ${BTN_COMMON}
  active:border-b-2
  active:rounded-none
  active:transition-none
  border-b-2
  border-transparent
  duration-100
  focus:border-b-2
  focus:duration-100
  focus:rounded-none
  focus:transition-all
  hover:border-b-2
  hover:duration-100
  hover:rounded-none
  hover:transition-all
  px-1
  transition-all
`

const PRIMARY_INDIGO: string = `
  ${PRIMARY_COMMON}
  active:bg-indigo-900
  bg-indigo-800
  focus:ring-indigo-300
  text-white
`

const PRIMARY_BLUE: string = `
  ${PRIMARY_COMMON}
  active:bg-indigo-900
  bg-indigo-800
  focus:ring-indigo-300
  text-white
`

const PRIMARY_RED: string = `
  ${PRIMARY_COMMON}
  active:bg-red-800
  bg-red-700
  focus:ring-red-400
  text-red-50
`

const PRIMARY_PURPLE: string = `
  ${PRIMARY_COMMON}
  active:bg-purple-800
  bg-purple-700
  focus:ring-purple-300
  hover:bg-purple-800
  text-purple-100
`

const PRIMARY_WHITE: string = `
  ${PRIMARY_COMMON}
  active:bg-white
  bg-white
  focus:ring-white
  text-purple-900
`

const SECONDARY_INDIGO: string = `
  ${SECONDARY_COMMON}
  active:bg-indigo-200
  bg-indigo-100
  border-indigo-700
  focus:ring-indigo-400
  hover:bg-indigo-200
  hover:text-indigo-800
  text-indigo-700
`

const SECONDARY_RED: string = `
  ${SECONDARY_COMMON}
  active:bg-red-200
  bg-red-100
  border-red-700
  focus:ring-red-400
  hover:bg-red-200
  hover:text-red-800
  text-red-700
`

const SECONDARY_PURPLE: string = `
  ${SECONDARY_COMMON}
  active:bg-purple-200
  bg-purple-100
  border-purple-700
  focus:ring-purple-400
  hover:bg-purple-200
  hover:text-purple-800
  text-purple-700
`

const SECONDARY_LIME: string = `
  ${SECONDARY_COMMON}
  active:bg-lime-300
  bg-lime-200
  border-lime-800
  focus:ring-lime-500
  text-lime-800
  rounded-full
`

const TERTIARY_LIME: string = `
  ${TERTIARY_COMMON}
  active:border-lime-800
  active:text-lime-800
  focus:border-lime-800
  hover:border-lime-800
  text-lime-800
`

const TERTIARY_YELLOW: string = `
  ${TERTIARY_COMMON}
  active:border-yellow-900
  active:text-yellow-900
  focus:border-yellow-900
  hover:border-yellow-900
  text-yellow-900
`

const TERTIARY_ORANGE: string = `
  ${TERTIARY_COMMON}
  active:border-orange-800
  active:text-orange-800
  focus:border-orange-800
  hover:border-orange-800
  text-orange-800
`

const TERTIARY_INDIGO: string = `
  ${TERTIARY_COMMON}
  active:border-indigo-900
  active:text-indigo-900
  focus:border-indigo-700
  hover:border-indigo-700
  text-indigo-700
`

const TERTIARY_RED: string = `
  ${TERTIARY_COMMON}
  active:border-red-900
  active:text-red-900
  focus:border-red-900
  hover:border-red-900
  text-red-900
`

const TERTIARY_PURPLE: string = `
  ${TERTIARY_COMMON}
  active:border-purple-800
  active:text-purple-800
  focus:border-purple-600
  hover:border-purple-600
  text-purple-600
`

const COLORS: Record<string, string> = {
  "primary-indigo": PRIMARY_INDIGO,
  "primary-blue": PRIMARY_BLUE,
  "primary-red": PRIMARY_RED,
  "primary-purple": PRIMARY_PURPLE,
  "primary-white": PRIMARY_WHITE,
  "secondary-indigo": SECONDARY_INDIGO,
  "secondary-red": SECONDARY_RED,
  "secondary-purple": SECONDARY_PURPLE,
  "secondary-lime": SECONDARY_LIME,
  "tertiary-indigo": TERTIARY_INDIGO,
  "tertiary-red": TERTIARY_RED,
  "tertiary-purple": TERTIARY_PURPLE,
  "tertiary-lime": TERTIARY_LIME,
  "tertiary-yellow": TERTIARY_YELLOW,
  "tertiary-orange": TERTIARY_ORANGE,
}

const LOADING_COLORS: Record<string, string> = {
  "purple": "hsl(273, 67%, 39%)",
  "red": "hsl(12, 86%, 47%)",
}

const DownloadIcon = () => {
  return (
    <>
      <i className="fad fa-cloud-download-alt" />&nbsp;
    </>
  )
}

const ExternalIcon = () => {
  return (
    <>
      &nbsp;<sup><i className="fas fa-external-link-alt text-tn" /></sup>
    </>
  )
}

const Button = forwardRef(({
  action,
  children,
  className,
  color,
  disabled,
  href,
  icon,
  loading = false,
  title,
  ...rest
}: ButtonProps, ref) => {
  const initialClassList: string = className && disabled
    ? `${className} opacity-30 cursor-not-allowed`
    : className
      ? className
      : disabled
        ? "opacity-30 cursor-not-allowed"
        : "";

  const formattedButton: string = formatClassList(
    appendVariantClasses(
      initialClassList,
      COLORS,
      `${action}-${color}`
    )
  );

  const download: boolean = icon === "download";
  const external: boolean = icon === "external";

  if (href) {
    if (icon && !external && !download) {
      return (
        <Link
          className={formattedButton}
          to={href}
          {...rest}
        >
          <button
            className="outline-none w-full"
            disabled={disabled}
            ref={ref as RefObject<HTMLButtonElement>}
            tabIndex={-1}
            title={title}
            type="button"
          >
            <span>
              {children}
              {loading &&
                <span>&nbsp;<ReactLoading type="spin" color={action !== "primary" ? LOADING_COLORS[color] : "#fff"} className="button-spinner" /></span>
              }
            </span>
          </button>
        </Link>
      )
    }

    return (
      <a
        className={formattedButton}
        href={href}
        rel="noopener noreferrer"
        target={icon && (icon === "external" || href.match(/\.pdf$/)) ? "_blank" : undefined}
        {...rest}
      >
        <button
          className="outline-none w-full"
          disabled={disabled}
          ref={ref as RefObject<HTMLButtonElement>}
          tabIndex={-1}
          title={title}
          type="button"
        >
          <span>
            {icon && icon === "download" && (
              <DownloadIcon />
            )}
            {children}
            {loading &&
              <span>&nbsp;<ReactLoading type="spin" color={action !== "primary" ? LOADING_COLORS[color] : "#fff"} className="button-spinner" /></span>
            }
            {icon && icon === "external" && (
              <ExternalIcon />
            )}
          </span>
        </button>
      </a>
    )
  }

  return (
    <button
      className={formattedButton}
      disabled={disabled}
      ref={ref as RefObject<HTMLButtonElement>}
      title={title}
      type="button"
      {...rest}
    >
      <span>
        {icon && icon === "download" && (
          <DownloadIcon />
        )}
        {children}
        {loading &&
          <span><ReactLoading type="spin" color={action !== "primary" ? LOADING_COLORS[color] : "#fff"} className="button-spinner" width={30} height={30} /></span>
        }
        {icon && icon === "external" && (
          <ExternalIcon />
        )}
      </span>
    </button>
  )
});

export default Button;
